.uploadText {
		text-align: center;
		font-weight: 400;
		line-height: 18px;
		font-size: 14px;
		color: #101928;
}

.uploadFooterText {
		text-align: center;
		font-weight: 400;
		line-height: 12px;
		font-size: 12px;
		color: rgba(103, 118, 140, 0.5);
}

.uploadButton {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #101928;
		border-radius: 6px;
		border: 1px solid #D1D6DD;
}