.modalHeader {
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	cursor: move;
}

.modalText{
	margin-bottom: 20px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #67768C;
}

.buttonOk {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

.buttonBack {
	composes: buttonOk;
	color: #101928;
	border: 1px solid #D1D6DD;
}