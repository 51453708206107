.container {
  border-radius: 8px;
}

.headerText {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.textBelow {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.headerTextEmployee {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #101928;
}

.employeeName {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.organisationInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.informationFile {
  display: flex;
  flex-direction: column;
}

.cause {
  display: flex;
  padding: 12px;
  flex-direction: column;
  border-radius: 6px;
  background: rgba(103, 118, 140, 0.05);
}

.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.actions {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #DAE2FF;
  background: rgba(52, 56, 224, 0.05);
}

.actionsHead {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #3438E0;
}

.modalText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
}

.actionsError {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #FFDCDC;
  background: rgba(255, 59, 48, 0.05);
}

.actionsText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
}

.button {
  height: 40px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.actionsHeadError {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #FF3B30;
}

.buttonErrorOneC {
  height: 40px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: #FF3B30 !important;
}

.buttonErrorOneC:hover {
  color: #FFF !important;
  background-color: #FF3B30 !important;
}

.notificationsContent {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
}

.docFileBlock {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docFile {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.docText {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.borderLine {
  border-top: 1px solid rgba(103, 118, 140, 0.20);
}

.notApproved {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.notApproved > div:not(:first-child) {
  margin-top: 12px;
}

.notApproved > div:not(:last-child) {
  margin-bottom: 12px;
}

.notApproved::-webkit-scrollbar {
  width: 4px;
}

.notApproved::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}

.clientStyle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #101928;
}

.employeeStyle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
}
