.container {
  border-radius: 8px;
}

.cause {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: rgba(103, 118, 140, 0.05);
}
