.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.createContractHeader {
  margin-bottom: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.createContractForm {
  margin-bottom: 12px;
  border-radius: 8px;
}

.createContractFormBlock {
  padding: 20px;
  margin-bottom: 12px;
  background-color: #fff;
  border-radius: 8px;
}

.createContractBlockHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.createContractActionBlock {
  border-radius: 8px;
}
