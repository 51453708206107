.modalText {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #67768C;
}

.noFiles {
	display: flex;
	padding: 8px 12px;
	margin-bottom: 20px;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: #F7F8F9;
}

.noFilesText {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.fileListing {
	margin-top: 12px;
	max-height: 400px;
	gap: 4px;
	overflow-y: auto;
	overflow-x: hidden;
}

.fileListing::-webkit-scrollbar {
	width: 4px;
}

.fileListing::-webkit-scrollbar-thumb {
	background: rgba(103, 118, 140, 0.30);
	border-radius: 50px;
}