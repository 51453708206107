.modalWrap div:nth-child(n + 1) {
  padding: 0;
}

.btnBorder {
  border: 1px solid rgba(103, 118, 140, 0.30) !important;
}

.imgBorderRadius {
  border-radius: 8px 0 0 8px;
}

.imgNameBorderRadius {
  border-radius: 6px;
}

.labelStyle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  display: flex;
  padding: 8px;
  text-align: center;
  color: #FFF;
  word-break: break-word;
}

.documentWrapScroll {
  height: 95vh;
  overflow-y: scroll;
}

.documentPdfStyle {
  height: 100vh;
  width: 50vw;
 }

.pdfPageHeight {
  height: 87vh;
  margin-bottom: 10px;
  overflow: hidden;
}

.pdfPageHeight:last-of-type {
  display: none;
}

.pdfLoading {
  position: absolute;
  top: 45vh;
  left: 21vw;
}

.agreementData::-webkit-scrollbar {
  display: none;
}

.agreementData {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.buttonOk {
  width: 100%;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.buttonBack {
  composes: buttonOk;
  text-align: center;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.informationCEO {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.modalHeaderText {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.modalText {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modalWrap div.formStyle {
  height: calc(100vh - 146px);
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  padding: 24px 24px 24px 0;
  overflow-y: auto;
}

.formStyle::-webkit-scrollbar {
  width: 5px;
}

.formStyle::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}


@media (min-height: 1000px) {
  .pdfPageHeight {
    height: 83vh;
  }
}

@media (min-height: 1200px) {
  .pdfPageHeight {
    height: 64vh;
  }
}
