.containerBlock {
  border-radius: 8px;
}

.skillsText {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  background: #F7F8F9;
}