.blockInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 76px;
  padding: 16px 16px 11px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #FFFFFF;
}

.blockInputData {
  height: auto;
  width: 100%;
  padding: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #FFFFFF;
}

.blockInputData .button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  background-color: #EDF1FF;
  color: #3438E0;
  border: none;
}

.input {
  width: 100%;
  height: 40px;
  margin: 0;
  font-size: 16px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.blockInput .button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 14px;
  background-color: #EDF1FF;
  color: #3438E0;
  border: none;
}

.blockInput .button svg {
  width: 17px;
  height: 17px;
}

.blockInput .button svg path {
  fill: #3438E0;
  transition: fill .2s ease-in-out;
}

.blockInput .button:hover svg path {
  fill: #4096ff;
}

.blockInput .button span {
  margin-left: 0.25rem;
  font-size: 14px;
  line-height: 18px;
}

.blockInput .button:hover {
  background-color: #EDF1FF;
  color: #3438E0;
}

.blockInputData .button svg {
  width: 17px;
  height: 17px;
}

.blockInputData .button svg path {
  fill: #3438E0;
  transition: fill .2s ease-in-out;
}

.blockInputData .button:hover svg path {
  fill: #4096ff;
}

.blockInputData .button span {
  margin-left: 0.25rem;
  font-size: 14px;
  line-height: 18px;
}

.blockInputData .button:hover {
  background-color: #EDF1FF;
  color: #3438E0;
}

.tag {
  display: flex;
  flex-wrap: wrap;
}

.tagBlock {
  display: flex;
}

.tagKey {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #8591A3;
}

.tagValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}

.alignedTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;
  border: none;
  background-color: #F7F8F9;
}

.tagButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  border-radius: 4px!important;
  border: none;
  color: #3438E0 !important;
  background-color: #EDF1FF !important;
}

.table table colgroup {
  width: 5%;
}
