.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.descrBase {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.historyStepBlock {
  width: calc(50% - 6px);
}

.historyHeader {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.taskStepDescr {
  composes: descrBase;
  font-weight: 500;
}

.fifthStepError {
  composes: descrBase;
  color: rgba(255, 59, 48, 0.80);
}

.stepsHeader {
  composes: descrBase;
  color: #67768C;
}

.openFormBtn {
  composes: flexCenter;
  height: 44px;
  padding: 13px 18px 13px 18px;
  composes: descrBase;
}

.btnBase {
  composes: flexCenter;
  height: 44px;
  padding: 5px 12px;
  margin-left: 8px;
  composes: descrBase;
}

.btnError1C {
  composes: btnBase;
  color: #FFF !important;
  background-color: #FF3B30 !important;
}

.btnError1C:hover {
  color: #FFF !important;
  background-color: #FF3B30 !important;
}
