.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.tableCell {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}
