.icon {
  margin-bottom: 7%;
}

.textHeader {
  margin-bottom: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.textBody {
  width: 250px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #67768C;
}
