.contractorCardInfoBlock {
  padding: 18px;
  margin-bottom: 12px;
  background: #fff;
  border-radius: 8px;
}

.contractorCardAboutHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.contractorCardAboutLabel {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.contractorCardAboutData {
  display: inline-block;
  width: 100%;
  line-height: 18px;
}
