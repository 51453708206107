.absenteesHeader {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modalRemoveTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #101928;
}

.modalRemoveText {
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.buttonBack {
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}
