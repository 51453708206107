.requiredField {
  margin-right: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #FF3B30;
}

.createApplicationHeader {
  margin: 0 0 18px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.createApplicationBlockHeader {
  margin: 0 0 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.createApplicationLabel {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
