.container {
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  padding: 18px 18px 10px 18px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.messagesContainer {
  display: flex;
  padding: 10px 18px;
  max-height: 574px;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  overflow-y: auto;
}

.messagesContainer::-webkit-scrollbar {
  width: 5px;
}

.messagesContainer::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}

.messageBlock {
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  gap: 12px;
  border-radius: 8px;
  background: #F7F8F9;
}

.editMessageBlock {
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #4870FE;
  background: #FFF;
}

.inputBlock {
  display: flex;
  padding: 18px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.textStyle {
  width: 80%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.commentText {
  overflow-wrap: break-word;
  word-break: normal;
  white-space: pre-wrap;
}

.buttonSend {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.messageText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.buttonCancel {
  margin-top: 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #101928;
  border: 1px solid #D1D6DD;
}
