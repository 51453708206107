.modalHeader {
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
	cursor: move;
}

.modalText{
	margin-bottom: 20px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #67768C;
}

.buttonOk {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

.buttonBack {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: #101928;
	border: 1px solid #D1D6DD;
}

.fileListing {
	margin-top: 12px;
	max-height: 400px;
	gap: 4px;
	overflow-y: auto;
	overflow-x: hidden;
}

.fileListing::-webkit-scrollbar {
	width: 4px;
}

.fileListing::-webkit-scrollbar-thumb {
	background: rgba(103, 118, 140, 0.30);
	border-radius: 50px;
}

.documentsAction {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	align-items: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	border-radius: 2px;
	color: #3438E0;
}

.documentsAction:hover {
	background: #F0F1F3;
}

.documentsAction .deleteIcon{
	opacity: 0;
	visibility: hidden;
}

.documentsAction:hover .deleteIcon {
	opacity: 1;
	visibility: visible;
}

.fileName {
	margin: 3px 0 3px 4px;
	max-width: 480px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}