.instructionsText {
  max-width: 960px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 240px;
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.17;
}

.collapseContainer {
  display: flex;
  justify-content: space-between;
}

.collapseContainer:hover .collapseIcon {
  border-radius: 50%;
  background-color: rgb(243 244 246);
}

.collapseLabel {
  margin-top: 10px;
  margin-left: 10px;
  height: 40px;
  font-size: 22px;
  line-height: 1.35;
  font-weight: 500;
}

.collapseIcon {
  margin-top: 5px;
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.collapseIcon.activeIcon {
  transform: rotate(-45deg);
  opacity: 1;
}

.instructionOption {
  margin-left: 10px;
  outline: none;
  color: #ff8562;
  text-decoration: none;
  font-size: 12px;
  line-height: 1.55;
  font-weight: 300;
}

.instructionOption:hover {
  color: #ff8562;
  text-decoration: none;
}