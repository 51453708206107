.modalTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #101928;
}

.modalText {
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
}

.buttonBack {
  font-weight: 500;
  color: #3438E0;
  border: 2px solid #3438E0;
}
