.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.input {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.tableCell {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}

.settingsBtn {
  background-color: transparent;
  color: transparent;
}

.settingsBtn svg path {
  transition: fill .2s ease-in-out;
}

.settingsBtn:hover {
  background-color: transparent!important;
  color: transparent;
}

.settingsBtn:hover svg path {
  fill: #4870FE;
  transition: fill .2s ease-in-out;
}

.docFile {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}