.collapseStyle {
	background: #FFFFFF;
}

.collapseLabel {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
}

.collapseContainer {
	display: flex;
	justify-content: space-between;
}

.collapseIcon {
	width: 18px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.collapseIcon.activeIcon {
	transform: rotate(-180deg);
	opacity: 1;
}