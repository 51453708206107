.accountsContainer {
  padding: 18px;
  background: #fff;
  border-radius: 8px;
}

.accountsHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.accountsSearchInput {
  width: 100%;
  height: 44px;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.accountsSearchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.noBanks {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  background-color: #FFF;
}
