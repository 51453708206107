.container {
  max-width: 1920px;
  height: 100%;
  padding: 16px;
}

.buttonReturn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0;
  margin-left: -5px;
  font-weight: 500;
  color: #3438E0;
}

.buttonReturn svg g path {
  fill: #3438E0;
  transition: fill .2s ease-in-out;
}

.buttonReturn:hover svg g path {
  fill: #69b1ff;
}

.employeeName {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.buttonBack {
  font-weight: 500;
  color: #3438E0;
  border: 2px solid #3438E0;
}
