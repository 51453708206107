.container {
  border-radius: 8px;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.documentLabel {
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}

.documentCreated {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #67768C;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 32px;
  padding: 7px 12px 7px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
