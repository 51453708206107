.container {
  border-radius: 8px;
}

.informationCEO {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.cause {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: rgba(103, 118, 140, 0.05);
}

.borderLine {
  border-top: 1px solid rgba(103, 118, 140, 0.20);
}
