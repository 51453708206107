.icon {
  position: absolute;
  left: 1.3rem;
  top: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 50px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #FF3B30;
  color: #FFF;
}
