.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.toAdmin {
  color: #101928;
  border: 1px solid #D1D6DD;
}
