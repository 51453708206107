body {
  background-color: #F3F4F6;
}

.container {
  max-width: 1920px;
  margin: 24px;
  height: 100%;
}

.headerText {
  padding: 18px 18px 10px 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px 8px 0 0;
  background-color: #FFFFFF;
}

.listing {
  padding: 10px 18px 18px 18px;
  border-radius: 0 0 8px 8px;
  background-color: #FFFFFF;
}

.input {
  width: 100%;
  height: 44px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.noEmployees {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  background-color: #FFF;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.columnText {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
}

.tooltip {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  color: #FFF;
}

.tooltip div span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
