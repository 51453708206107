.tableCell {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}

.skillStyle {
  width: fit-content;
  height: fit-content;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  color: #67768C;
  background: #F7F8F9;
}

.popoverStyle {
  width: 160px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.ellipsisStyle {
  width: fit-content;
  height: fit-content;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  color: #67768C;
  background: #F7F8F9;
  cursor: pointer;
}

.ellipsisStyle:hover {
  color: #101928;
  background: #E8EAEE;
}