.modalHeader {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #101928;
  cursor: move;
}

.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  width: 125px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.modalText{
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
}

.spaceStyle {
  width: 100%;
}

.inputStyle {
  width: 50%;
}
