@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .mx-w-420px {
    max-width: 420px;
    min-width: 420px;
  }

  .w-24px {
    width: 24px;
  }
  
  .w-6rem {
    width: 6rem;
  }

  .w-7rem {
    width: 7rem;
  }

  .w-8rem {
    width: 8rem;
  }

  .h-100vh {
    height: 100vh;
  }

  .h-95vh {
    height: 95vh;
  }

  .h-90vh {
    height: 90vh;
  }

  .h-85vh {
    height: 85vh;
  }
  
  .max-h-60vh {
    max-height: 60vh;
  }

  .min-h-80 {
    min-height: 80%;
  }

  .left-2rem {
    left: 2.5rem;
  }

  .right-10rem {
    left: 10.7rem;
  }

  .top-1rem {
    top: 1.1rem;
  }
  
  .top-50 {
    top: 50%;
  }

  .mt-auto {
    margin: auto 0 0 0;
  }

  .border-gray {
    border: 1px solid #67768C33;
  }

  .border-lightgray {
    border: 1px solid #f0f0f0;
  }

  .border-r-gray {
    border-right: 1px solid lightgray;
  }

  .border-b-gray {
    border-bottom: 1px solid rgba(103, 118, 140, 0.20);
  }

  .menu-divider {
    border-top: 1px solid lightgray;
    width: 90%;
    margin-left: 8px;
    background-color: #67768C33;
  }

  .menu-btn-black {
    display: flex;
    width: 24px;
    height: 72px;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-color: inherit;
  }

  .menu-btn-black:hover {
    background-color: inherit;
  }
  
  .box-shadow-none {
    box-shadow: none;
  }

  .gray-color {
    color: #67768C;
  }
  
  .counter-gray-color {
    color: #8591A3;
  }

  .bg-gray {
    background-color: #67768C;
  }

  .blue-color {
    color: #3438E0;
  }

  .warning-color {
    color: #FF9500;
  }

  .confirmedColor {
    color: #34C759;
  }

  .text-14px {
    font-size: 14px;
  }

  .text-16px {
    font-size: 16px;
  }
  
  button:not(:disabled):hover {
    background-color: #FFF;
  }

  .header-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .field-label {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }

  .document-field {
    display: inline-block;
    max-width: 250px;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    color: gray;
  }

  .document-field-viewer {
    display: inline-block;
    max-width: 165px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: gray;
  }
  
  .textBlock {
    display: flex;
    align-items: start;
    margin-bottom: 8px;
  }
  
  .textBlock span:first-child {
    width: 30%;
  }
  
  .textBlock span:last-child {
    max-width: 70%;
    width: auto;
  }
  
  .textBlockTariff {
    display: flex;
    align-items: start;
    margin-bottom: 8px;
  }
  
  .textBlockTariff span:first-child {
    width: 50%;
  }
  
  .textBlockTariff span:last-child {
    max-width: 50%;
    width: auto;
  }
  
  .textBlockWidth {
    display: flex;
    align-items: start;
    margin-bottom: 8px;
  }
  
  .textBlockWidth span:first-child {
    width: 50%;
  }
  
  .textBlockWidth span:last-child {
    width: 50%;
  }
  
  .textBlock1C {
    display: flex;
    align-items: start;
    margin-bottom: 8px;
  }
  
  .textBlock1C span:first-child {
    width: 20%;
  }
  
  .textBlockWidth span:last-child {
    width: 80%;
  }
  
  .text1CStyle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  
  .checkFiles {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
  
  .table-filters-wrap {
    padding: 12px 16px;
  }
  
  .table-filters {
    display: flex;
    flex-direction: column;
  }
  
  .table-checkbox {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  
  .table-checkbox:checked {
    background: #EDF1FF;
  }
  
  .table-btns {
    display: flex;
    justify-content: flex-end;
  }

  .table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #8591A3;
    cursor: pointer;
  }

  .table-title:hover {
    color: #67768C;
  }

  .container-block {
    max-width: 1920px;
    margin: 16px;
    height: 100%;
  }

  .description-base {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .search-color {
    color: #67768C;
  }
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-Medium.eot");
  src: local("HelveticaNeueCyr-Medium"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-MediumItalic.eot");
  src: local("HelveticaNeueCyr-MediumItalic"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-Italic.eot");
  src: local("HelveticaNeueCyr-Italic"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaNeueCyr";
  src: url("./assets/fonts/HelveticaNeueCyr-Roman.eot");
  src: local("HelveticaNeueCyr-Roman"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.woff2") format("woff2"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.woff") format("woff"),
  url("./assets/fonts/HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.eot");
  src: local("Roboto"), local("Roboto-Regular"),
  url("./assets/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
  url("./assets/fonts/Roboto-Regular.woff") format("woff"),
  url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-MediumItalic.eot");
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
  url("./assets/fonts/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/Roboto-MediumItalic.woff2") format("woff2"),
  url("./assets/fonts/Roboto-MediumItalic.woff") format("woff"),
  url("./assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Italic.eot");
  src: local("Roboto Italic"), local("Roboto-Italic"),
  url("./assets/fonts/Roboto-Italic.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/Roboto-Italic.woff2") format("woff2"),
  url("./assets/fonts/Roboto-Italic.woff") format("woff"),
  url("./assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.eot");
  src: local("Roboto Medium"), local("Roboto-Medium"),
  url("./assets/fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
  url("./assets/fonts/Roboto-Medium.woff") format("woff"),
  url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 250 1000;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 300;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Light.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Light.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Light.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 400;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Regular.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Regular.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 500;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Medium.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Medium.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 600;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Semibold.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Semibold.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 700;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Bold.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Bold.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 800;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Extrabold.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Extrabold.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Extrabold.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Extrabold.woff') format('woff');
}

@font-face {
  font-family: 'TildaSans';
  font-style: normal;
  font-weight: 900;
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Black.eot');
  src: url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Black.eot?#iefix') format('embedded-opentype'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff2') format('woff2-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-VF.woff') format('woff-variations'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Black.woff2') format('woff2'),
  url('https://static.tildacdn.com/fonts/tildasans/TildaSans-Black.woff') format('woff');
}

body {
  min-height: 100vh;
  background-color: #F3F4F6;
  color: #101928;
  font-family: "HelveticaNeueCyr", Roboto, sans-serif;
}
