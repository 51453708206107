.container {
  max-width: 400px;
  margin: 23vh auto;
  padding: 32px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 5px 60px 0 rgba(103, 118, 140, 0.15);
}

.containerReset {
  margin: auto;
  font-size: 14px;
}

.header {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.underHeader {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.input {
  padding: 6px 11px;
  font-size: 1.6rem;
}

.confirmPasswordMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20em;
}

.resetText {
  display: block;
  text-align: center;
  width: 337px;
  margin-bottom: 20px;
  padding: 0 22px 0 22px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #67768C;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.actions {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(52, 56, 224, 0.05);
}

.checkBox {
  display: flex;
  padding: 12px;
  margin-bottom: 20px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: #EDF1FF;
}

.checkText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #111827;
}
