.applicationHeader {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.applicationCardHeader {
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
