.bannerDraft {
		display: flex;
		margin-bottom: 12px;
		padding: 12px 18px;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		border-radius: 8px;
		background: #E8EAEE;
}