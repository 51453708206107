.changeResponsibleHeader {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: move;
}

.changeResponsibleDescription {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.changeResponsibleSearchIcon {
  width: 20px;
  height: 20px;
}

.changeResponsibleSelect {
  width: 100%;
  margin-bottom: 20px;
}
