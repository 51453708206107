.terminateText {
		margin-bottom: 20px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #67768C;
}

.terminateTitle {
		margin-bottom: 12px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
}

.terminateCause {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
}

.changeCause {
		width: 100%;
		margin-top: 12px;
		margin-bottom: 20px;
		display: flex;
		padding: 5px 12px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		align-self: stretch;
		border-radius: 6px;
		background: #EDF1FF;
}