.tariffTitle {
		margin-right: 12px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
}

.tariffButton {
		display: flex;
		padding: 1px 8px;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: black;
		border-radius: 4px;
		border: 1px solid #D1D6DD;
}

.tariffText {
		margin-bottom: 20px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #67768C;
}

.changeOptions {
		width: 100%;
		margin-top: 12px;
		margin-bottom: 20px;
		display: flex;
		padding: 5px 12px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		align-self: stretch;
		border-radius: 6px;
		background: #EDF1FF;
}

.tariffContent {
		margin-bottom: 8px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #101928;
}