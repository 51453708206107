.headerContent {
	margin-bottom: 8px;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	color: #101928;
}

.bodyContent {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #101928;
}