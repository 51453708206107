.modalTitle {
	display: block;
	margin-bottom: 20px;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.textStyle {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.modalHeader {
	margin-bottom: 4px;
	composes: textStyle;
	color: #67768C;
}

.userName {
	margin-bottom: 20px;
	composes: textStyle;
}

.modalText {
	margin-bottom: 20px;
	composes: textStyle;
	color: #67768C;
}

.searchIcon {
	width: 25px;
	height: 25px;
	opacity: 50%;
	color: #67768C;
}

.infoBlock {
	margin-bottom: 20px;
	display: flex;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 12px;
	background: #F7F8F9;
}