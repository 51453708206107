.userBlock {
  width: 30%;
  padding: 24px;
  background-color: #FFF;
  border-radius: 8px;
}

.personalDataBlock {
  width: 70%;
  background-color: #FFF;
  border-radius: 8px;
}

.personalDataBlockWrap {
  border-bottom: 2px solid #F3F4F6B2;
}

.personalDataBlockHeader {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.userLabel {
  margin-bottom: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
