.container {
  border-radius: 8px;
}

.validationContainer {
  margin: 16px 156px 120px;
}

.buttonReturn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: 500;
}

.employeeName {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.buttonBack {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}
