body {
  background-color: #F9F9F9;
}

.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.tableButton {
  display: flex;
  justify-content: center;
  border-radius: 50px;
}

.tableCell {
  margin: -16px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.tableCellStatus {
  cursor: pointer;
}

.modalHeader {
  margin-left: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
}

.modalInput {
  height: 5vh;
}

.modalTextTask {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.modalDrop {
  width: 100%;
}

.modalDropButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalLine {
  border-top: 1px solid #ddd
}

.modalFooter {
  padding: 8px 8px;
}

.modalFooterButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalFooterButton > :first-child {
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonStyle {
  display: flex;
  width: 159px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.responsible div {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 1px 0 1px 0;
}

.blockPosition {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #67768C;
}

.blockResponsible {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.blockResponsibleTag {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  border: none;
  background: #F0F1F3;
}

.blockResponsibleTag div {
  width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  color: #8591A3 !important;
}

.tableHeader:hover {
  color: #67768C !important;
}

.statusDefault {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: 1px solid #4870FE;
  background-color: #FFFFFF;
  color: #4870FE;
}

.statusDefault:hover {
  cursor: pointer;
}

.statusWork {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-color: #FF9500;
  background-color: #FF9500;
  color: #FFFFFF;
}

.statusWork:hover {
  cursor: pointer;
}

.statusTrue {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-color: #34C759;
  background-color: #34C759;
  color: #FFFFFF;
}

.statusTrue:hover {
  cursor: pointer;
}

.statusFalse {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-color: #FF3B30;
  background-color: #FF3B30;
  color: #FFFFFF;
}

.statusFalse:hover {
  cursor: pointer;
}

.selectImplementer {
  width: 100%;
}

.searchIcon {
  width: 15px;
  height: 15px;
  opacity: 50%;
  color: #67768C;
}

.settingsBtn {
  background-color: transparent;
  color: transparent;
}

.settingsBtn svg path {
  transition: fill .2s ease-in-out;
}

.settingsBtn:hover {
  background-color: transparent!important;
  color: transparent;
}

.settingsBtn:hover svg path {
  fill: #4870FE;
  transition: fill .2s ease-in-out;
}

.settingsBtnDisable {
  background-color: transparent;
  color: transparent;
}

.settingsBtnDisable svg path {
  transition: fill .2s ease-in-out;
}

.settingsBtnDisable:hover {
  background-color: transparent!important;
  color: transparent;
}

.counterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5.5px 6.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border-radius: 24px;
}

.bellUnRead {
  background-color: transparent;
  color: transparent;
}

.bellUnRead svg path {
  transition: fill .2s ease-in-out;
}

.bellUnRead:hover {
  background-color: transparent!important;
  color: transparent;
}

.bellUnRead:hover svg path{
  fill: #101928;
  transition: fill .2s ease-in-out;
}

.redBallIcon {
  position: absolute;
  transform: translate(100%, -200%);
}

.tooltip {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.notificationError {
  display: flex;
  margin-top: -0.55rem;
  max-width: 520px;
  min-width: 240px;
}

.notificationsUpdate {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.notificationsUpdateText {
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.middlePriority {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #D1D6DD;
  background: #FFF;
}

.highPriority {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #FFCCC7;
  background: #FFF2F0;
}
