.modalTitle {
	display: block;
	margin-bottom: 20px;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.textStyle {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.modalHeader {
	margin-bottom: 4px;
	composes: textStyle;
	color: #67768C;
}

.userName {
	margin-bottom: 20px;
	composes: textStyle;
}