.menu {
  max-width: 1200px;
  margin: 0 auto;
  height: 80px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoStyle {
  width: 180px;
  height: auto;
  display: block;
}

.menuBurger {
  display: none;
}

.commonBurgerButtonStyles {
  width: 28px;
  height: 27px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0;
  background-color: #f3f4f6 !important;
  border: none;
}

.menuBurgerButton {
  box-shadow: none;
}

.menuBurgerButtonStyle {
  cursor: pointer;
  transition: height 0.3s ease-in-out;
  align-items: center;
}

.menuBurgerButtonStyle:not(.active):hover {
  height: 17px !important;
  background-color: #f3f4f6 !important;
}

.menuBurgerButtonStyle.active:hover {
  background-color: #f3f4f6 !important;
}

.burgerLine {
  width: 100%;
  height: 3px;
  background-color: #3438e0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.menuBurgerButtonStyle.active .topLine {
  transform: translateY(12px) rotate(45deg);
}

.menuBurgerButtonStyle.active .middleLine {
  opacity: 0;
}

.menuBurgerButtonStyle.active .bottomLine {
  transform: translateY(-12px) rotate(-45deg);
}

.textMenu {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.commonButtonStyles {
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 700;
  border: 2px solid #3438e0;
  border-radius: 0 !important;
  cursor: pointer;
}

.buttonEnterStyle {
  width: 100px;
  color: #3438e0;
  background-color: #f3f4f6 !important;
}

.buttonRegistrationStyle {
  color: #f2f3f5;
  background-color: #3438e0 !important;
  text-align: center;
}

@media (max-width: 980px) {
  .menu {
    display: none;
  }

  .menuBurger {
    height: 64px;
    padding: 40px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}