.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.listing {
  border-radius: 0 0 8px 8px;
  background-color: #FFFFFF;
}

.input {
  width: 100%;
  height: 44px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.noEmployees {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  background-color: #FFF;
}
