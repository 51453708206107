.container {
  border-radius: 8px;
}

.headerText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-self: stretch
}

.list:hover {
  cursor: pointer;
  background-color: #F3F4F6;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  color: #67768C !important;
}

.tableCell {
  margin: -16px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.buttonBack {
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
