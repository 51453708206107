.clarificationFilesList {
  gap: 4px;
  max-height: 400px;
  margin-top: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.clarificationFilesList::-webkit-scrollbar {
  width: 4px;
}

.clarificationFilesList::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}
