.tableHeader {
  background-color: unset !important;
  color: #67768C !important;
}

.tableCell {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}
