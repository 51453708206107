.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  color: #67768C !important;
}

.tableCell {
  margin: -16px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
  gap: 5px;
  cursor: pointer;
}

.statusNew {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 50px;
  border: 1px solid #91caff;
  background: #e6f4ff;
  color: #1677ff;
}

.statusDefault {
  padding: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.statusWork {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 50px;
  border: 1px solid #FFE58F;
  background: #FFFBE6;
  color: #FAAD14;
}

.statusError {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border-radius: 50px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: 1px solid #FFCCC7;
  background: #FFF2F0;
  color: #FF4D4F;
}

.buttonSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #3438E0;
}

.buttonError {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  background-color: #FF3B30 !important;
}

.buttonError:hover {
  color: #FFF !important;
  background-color: #FF3B30 !important;
}

.tooltipText {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: center;
}

.reportListTableHeader {
  display: block;
  margin-right: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768CCC;
}
