.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.returnBtn {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 8px;
  font-weight: 500;
}

.contractorCardHeader {
  margin-bottom: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
