.container {
  max-width: 1920px;
  margin: 24px;
  height: 100%;
}

.buttonReturn {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 500;
}

.containerHeader {
  margin: 4px auto;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

