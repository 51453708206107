.headerContainer {
  position: sticky;
  top: 0;
  padding: 12px 156px;
  border-bottom: 1px solid #E8EAEE;
  z-index: 21;
}

.headerLabel {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.container {
  margin: 18px 156px 18px;
  background: #F7F8F9;
}

.containerHeader {
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.collapse {
  width: 100%;
  background-color: #F7F8F9;
}

.up {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.down {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.headerText {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.actions {
  padding: 15px;
  border-radius: 8px;
  background: rgba(72, 112, 254, 0.10);
}

.actionsHead {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #101928;
}

.actionsBody {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.buttonExit {
  padding: 8px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}
