.container {
    display: flex;
    width: 100%;
    height: calc(100vh - 160px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    max-width: 1920px;
    max-height: 1080px;
    margin-top: 24px;
    border-radius: 8px;
    background-color:  #FFF;
}

.label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 15%;
}

.icon {
    margin-bottom: 7%;
}

.textHeader {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 2%;
    color: #101928;
}

.textBody {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #67768C;
}
