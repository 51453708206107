.card {
  width: 100%;
  margin-bottom: 12px;
}

.card > div {
  padding: 18px !important;
}

.cardsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.clientData {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
}

.toMHD {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #3438E0;
}

.editButton {
  padding-right: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.buttonBank {
  margin-top: -12px;
}

.tabsButtonActive {
  font-weight: 500;
  color: #3438E0;
  border-radius: 6px;
  border: 1.5px solid #3438E0;
}

.tabsButtonNotActive {
  font-weight: 500;
  color: #3438E0;
  border-radius: 6px;
  border: 1.5px solid rgba(72, 112, 254, 0.10);
}

.skeletonMask {
  width: 100%;
  min-width: 110px;
}

:global .ant-tabs-nav::before {
  border-color: #E1E4E8 !important;
  border: 1px solid;
}

.cardItemLabel {
  display: block;
  width: 100%;
  max-width: 250px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.cardItemValue {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
