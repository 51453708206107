.modalTitle {
	margin-bottom: 12px;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.modalText {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #67768C;
}