.flexColumnCenter {
  display: flex;
  align-items: center;
}

.uploadedFilesStyle {
  width: 100%;
  height: 164px;
  margin-top: 20px;
  border-radius: 8px;
  font-weight: normal;
}

.uploadingStyle {
  padding: 8px 12px;
  color: #101928;
  background: #F7F8F9;
  border-radius: 8px;
}

.uploadingResult {
  composes: flexColumnCenter;
  composes: uploadingStyle;
}

.filesUploading {
  composes: flexColumnCenter;
  composes: uploadedFilesStyle;
  color: #67768C;
  background: rgba(52, 56, 224, 0.05);
}

.processingButton {
  composes: flexColumnCenter;
  flex-direction: row;
  height: 42px;
  width: 100%;
  padding: 12px 18px 12px 18px;
  font-style: normal;
  font-weight: 500;
}

.uploadedFiles {
  margin-top: 12px;
  max-height: 400px;
  gap: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}

.uploadedFiles::-webkit-scrollbar {
  width: 4px;
}

.uploadedFiles::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}
