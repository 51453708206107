.userLabel {
  margin-bottom: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.accountantTag {
  max-width: 4.3rem;
}

.signRightsTag {
  max-width: 13.3rem;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  color: #8591A3 !important;
}

.tableHeader:hover {
  color: #67768C !important;
}

.tableCell {
  margin: -16px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.table table colgroup {
  width: 5%;
}

.tableButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #3438E0;
}

.inputEditable {
  width: 70px;
}

.settingsBtn {
  background-color: transparent;
  color: transparent;
}

.settingsBtn svg path {
  transition: fill .2s ease-in-out;
}

.settingsBtn:hover {
  background-color: transparent!important;
  color: transparent;
}

.settingsBtn:hover svg path {
  fill: #4870FE;
  transition: fill .2s ease-in-out;
}

.saveNewDataButton {
  display: flex;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
}
