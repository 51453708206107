.createReportHeader {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.createReportDescr {
  display: block;
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.reportTypeHeader {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.reportTableChoice {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.reportTableHeader {
  display: block;
  margin-right: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768CCC;
}

.reportTableSubrow {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #67768CCC;
}
