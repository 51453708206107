.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.cardsHeader {
  display: flex;
  justify-content: space-between;
}

.cardsHeader > :first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.buttonReturn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0;
  margin-bottom: 8px;
  margin-left: -5px;
  font-weight: 500;
  color: #3438E0;
}

.buttonReturn svg g path {
  fill: #3438E0;
  transition: fill .2s ease-in-out;
}

.buttonReturn:hover svg g path {
  fill: #69b1ff;
}

.header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.counterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5.5px 6.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border-radius: 24px;
}

.buttonChange {
  height: 40px;
  margin-right: 8px;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
}

.dropdownButton {
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E1E4E8;
}
