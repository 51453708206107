.modalHeader {
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;
}

.buttonBack {
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		color: #101928;
		border: 1px solid #D1D6DD;
}

.buttonOk {
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
}

.commentLabel {
		margin-bottom: 20px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #67768C;
}