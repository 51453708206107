.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px 18px 30px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #FFFFFF;
}

.paginationCustom {
  margin-right: 16px;
}

.select {
  border-radius: 6px;
  border: 1px solid #E1E4E8;
}

:global .ant-pagination .ant-pagination-options {
  display: none;
}
