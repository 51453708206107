.container {
  max-width: 1920px;
  max-height: 1080px;
  margin: 16px;
}

.containerCollapsed {
  composes: container;
  width: 50%;
}

.cardsHeader > :first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cardsHeaderAdmin > :first-child {
  display: flex;
  justify-content: space-between;
}

.primaryButton {
  display: flex;
  justify-content: center;
}

.clientName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #67768C;
}

.cardsContent {
  display: flex;
  justify-content: space-between;
}

.cardStatus {
  border-radius: 50px;
  pointer-events: none;
}

.cardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cardInfo > :first-child {
  width: inherit;
}

.cardInfoText {
  display: flex;
  justify-content: space-between;
}

.cardInfoBlock {
  width: 228px;
  margin-left: 0;
  margin-bottom: 23px;
}

.cardInfoBlock >:first-child {
  margin-bottom: 23px;
}

.cardInfoBlockClientName {
  margin-bottom: 23px;
}

.cardInfoBlockTask {
  margin-left: 0;
  margin-top: 23px;
}

.tooltip {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  color: #FFF;
}

.tooltip div {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.docFile {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardInfoHeaders {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #67768C;
}

.cardInfoHeadersTerm {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #67768C;
  cursor: default;
}

.cardInfoDocuments {
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.cardsContent div ul li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 97% !important;
}

.cardInfo > :first-child {
  width: inherit;
}

.cardsContent div ul li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 97% !important;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 13px 18px 13px 18px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.buttonAction {
  height: 44px;
  margin-right: 16px;
  padding: 13px 18px 13px 18px;
  font-size: 15px;
  border: 1px solid #D1D6DD;
  color: #101928;
}

.notificationsContent {
  margin-right: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
}

.notificationError {
  display: flex;
  margin-top: -0.55rem;
  max-width: 520px;
  min-width: 240px;
}

.processTask {
  margin-top: 12px;
  padding: 7px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #FF3B30;
}

.error1C {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.error1CText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
