.container {
  max-width: 1920px;
  max-height: 1080px;
  margin: 8px 24px 24px 30px;
}

.header {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.cardsHeader > :first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cardsHeaderAdmin > :first-child {
  display: flex;
  justify-content: space-between;
}

.buttonReturn {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 500;
}

.clientName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #67768C;
}

.cardsContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card {
  width: calc(50% - 6px);
}

.card:first-child {
  width: calc(50% - 6px);
}

.cardStatus {
  border-radius: 50px;
  pointer-events: none;
}

.cardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cardInfo > :first-child {
  width: inherit;
}

.cardInfoBlock {
  margin-left: 0;
}

.cardInfoBlockTask {
  margin-left: 0;
  margin-top: 23px;
}

.tooltip {
  display: flex;
  padding: 6px 8px;
  flex-direction: column;
  align-items: flex-start;
  color: #FFF;
}

.tooltip div {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.docFile {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardInfo > :first-child {
  width: inherit;
}

.buttonAction {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  margin-right: 16px;
  padding: 13px 18px 13px 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border: 1.5px solid #3438E0;
  color: #3438E0;
}

.notificationsContent {
  margin-right: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
}

.notificationError {
  display: flex;
  margin-top: -0.55rem;
  max-width: 520px;
  min-width: 240px;
}

.processTask {
  margin-top: 12px;
  padding: 7px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #FF3B30;
}

.taskInfoHeader {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.taskInfoLabel {
  width: 100%;
  max-width: 251px;
  margin-bottom: 9px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.taskInfoValue {
  width: 100%;
  min-width: 233px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  white-space: pre-line;
  word-break: break-word;
}

.taskStepsHeader {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
