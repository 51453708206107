b {
  font-weight: normal!important;
  background: rgba(255, 149, 0, 0.22);
}

.modalWrap {
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

.notice {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  border-radius: 8px;
  background: rgba(72, 112, 254, 0.1);
}

.noticeText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
}

.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

:global(.rc-virtual-list .rc-virtual-list-scrollbar) {
  width: 5px !important;
}

:global(.rc-virtual-list .rc-virtual-list-scrollbar-thumb) {
  background: rgba(103, 118, 140, 0.30) !important;
  border-radius: 50px !important;
}

:global(.rc-virtual-list) {
  scrollbar-color: rgba(103, 118, 140, 0.30) transparent !important;
  scrollbar-width: thin !important;
}

.declineDocMessge {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  margin-bottom: 25px;
  background: #EDF1FF;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
