.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background: #F7F8F9;
}

.textHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.textBody {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
