.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.containerBlock {
  border-radius: 8px;
}

.createVacancyHeader {
  margin-bottom: 18px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.createVacancyBlockHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.blockSkillsTag {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  border: none;
  background: #F0F1F3;
}

.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
