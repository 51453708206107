.fileName {
	display: inline-block;
	margin: 3px 0 3px 4px;
	max-width: 34vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.documentsAction {
	width: 100%;
	display: block;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	border-radius: 2px;
	color: #3438E0;
}

.documentsAction:hover {
	background: #F0F1F3;
}

.documentsAction .deleteIcon{
	display: inline-block;
	opacity: 0;
	visibility: hidden;
}

.documentsAction:hover .deleteIcon {
	opacity: 1;
	visibility: visible;
}