.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.modalHeader {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: move;
}

.modalText{
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.parentTask {
  width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
}

.documentsUpload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 2px;
  color: #3438E0;
}

.documentsUpload:hover {
  background: #F0F1F3;
}

.documentsUpload .deleteIcon{
  opacity: 0;
  visibility: hidden;
}

.documentsUpload:hover .deleteIcon {
  opacity: 1;
  visibility: visible;
}

.deleteIcon {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 6px;
  border-radius: 2px;
  background-color: inherit;
  cursor: pointer;
}

.deleteIcon:hover {
  background: #E1E4E8;
}

.buttonSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #3438E0;
}

.buttonClose {
  display: flex;
  width: 32px;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
}

.fileName {
  margin: 3px 0px 3px 4px;
  max-width: 480px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileListing {
  margin-top: 8px;
  max-height: 400px;
  gap: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fileListing::-webkit-scrollbar {
  width: 4px;
}

.fileListing::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}
