.container {
  position: absolute;
  left: 12.5rem;
  width: 416px;
  height: 100vh;
  padding-top: 16px;
  z-index: 8;
  background-color: #FFFFFF;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.05);
  transition: transform 1s;
  transform: translateX(0);
}

.containerCollapsed {
  position: absolute;
  left: calc(5rem - 12px);
  width: 416px;
  height: 100vh;
  padding-top: 16px;
  z-index: 8;
  background-color: #FFFFFF;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.05);
  transition: transform 1s;
  transform: translateX(0);
}

.hidden {
  position: absolute;
  left: 12.5rem;
  width: 416px;
  height: 100vh;
  padding-top: 16px;
  z-index: 8;
  background-color: #FFFFFF;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.05);
  transform: translateX(-100%);
  visibility: hidden;
  transition: all 1s;
}

.hiddenCollapsed {
  position: absolute;
  left: calc(5rem - 12px);
  width: 416px;
  height: 100vh;
  padding-top: 16px;
  z-index: 8;
  background-color: #FFFFFF;
  transform: translateX(-100%);
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.05);
  visibility: hidden;
  transition: all 1s;
}

.header {
  position: sticky;
  top: 0;
  padding: 0 24px 2px 24px;
  background-color: #FFFFFF;
}

.headerTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.notificationsDate {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #8591A3;
  background-color: #fff;
  z-index: 1;
}

.notificationsHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.notificationsBody {
  display: -webkit-box;
  max-height: 54px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.notificationsTaskData {
  height: fit-content;
  padding: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #67768C;
  border-radius: 4px;
  background: #F7F8F9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notificationData {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  color: #67768C;
}

.line {
  display: block;
  height: 1px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: rgba(103, 118, 140, 0.20);
}

.scroll {
  height: calc(100vh - 145px);
  overflow-y: auto;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scrollViewedAll {
  height: calc(100vh - 185px);
  padding-bottom: 10px;
  overflow-y: auto;
}

.scrollViewedAll::-webkit-scrollbar {
  width: 5px;
}

.notificationsContent {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #FFFFFF;
}

.counterLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5.5px 6.5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border-radius: 24px;
}

.notificationOk {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.notificationError {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #FF3B30 !important;
  background: #FFEDED !important;
}

.notificationError:hover {
  color: #FF3B30 !important;
  background: #FFEDED !important;
}

.notificationViewedAll {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #E8EAEE;
  z-index: 20;
}

.buttonViewedAll {
  display: flex;
  width: 100%;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 6px;
}

.tooltip {
  display: flex;
  width: 200px;
  padding: 8px;
  text-align: center;
  color: #FFF;
}

.messageText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
