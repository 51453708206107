.blockTable {
  height: auto;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.blockInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.blockInputData {
  height: auto;
  width: 100%;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.blockInputData .button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  background-color: #EDF1FF;
  color: #3438E0;
  border: none;
}

.input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  margin-right: 8px;
}

.numberInput {
  composes: input;
  width: 100%;
  max-width: 248px;
  padding-right: 9px;
  font-size: 15px;
}

.searchIcon {
  color: #B3BAC5;
}

.blockInput .button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  background-color: #EDF1FF;
  color: #3438E0;
  border: none;
}

.blockInput .button span {
  font-size: 14px;
  line-height: 18px;
}

.blockInput .button:hover {
  background-color: #EDF1FF;
  color: #3438E0;
}

.blockInputData .button span {
  font-size: 14px;
  line-height: 18px;
}

.blockInputData .button:hover {
  background-color: #EDF1FF;
  color: #3438E0;
}

.table table colgroup {
  width: 5%;
}

.tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.tagBlock {
  display: flex;
  margin-right: 4px;
}

.tagKey {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #3438E0;
}

.tagValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3438E0;
}

.commonTagStyle {
  display: flex;
  padding: 5px 12px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 24px;
}

.alignedTag {
  margin-bottom: 8px;
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
}

.tagButton {
  margin-bottom: 8px;
  border: 1px solid #D1D6DD;
  background: #F7F8F9;
  color: #101928;
  cursor: pointer;
}

.tagButton:hover {
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
}

.quickFilters {
  border: 1px solid #D1D6DD;
  background: #F7F8F9;
  cursor: pointer;
}

.quickFilters:hover {
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
}

.activeFilters {
  border: 1px solid #A3B7FF;
  background: #EDF1FF;
  color: #3438E0;
  cursor: pointer;
}

.noTasks {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  background-color: #FFF;
}

.borderLine {
  margin: 8px 0;
  height: 1px;
  align-self: stretch;
  background: #E1E4E8;
}
