.container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 6px);
  border-radius: 8px;
}

.cause {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: rgba(103, 118, 140, 0.05);
}

.passportData {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.passportData li {
  flex-basis: 33%;
}
