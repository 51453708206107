.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  composes: flexCenter;
  gap: 24px;
  width: 100%;
  max-height: 488px;
  background-color:  #FFF;
}

.label {
  composes: flexCenter;
  width: 25%;
  height: 15%;
}

.icon {
  margin-bottom: 8px;
}

.textHeader {
  margin-bottom: 2%;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #101928;
}

.textBody {
  width: 321px;
  word-wrap: break-word;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
