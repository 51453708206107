.functionality {
  max-width: 850px;
  margin: 0 auto 105px auto;
  padding-top: 90px;
  text-align: center;
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
}

.optionsStyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 30px;
}

.imgStyle {
  width: 50px;
}

.optionsText {
  margin-top: 25px;
  text-align: center;
  font-size: 18px;
  line-height: 1.35;
  font-weight: 600;
}

@media (max-width: 980px) {
  .optionsStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}