.container {
  border-radius: 8px;
}

.input {
  width: 100%;
  height: 42px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.tableCell {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

.taskTopic {
  display: flex;
  align-items: start;
  flex-direction: column;
  cursor: pointer;
}

.taskTopic span:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #67768C;
}

.taskTopic span:first-child:hover {
  color: #69b1ff;
}

.taskTopic span:nth-child(2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}

.taskTopic span:nth-child(2):hover {
  color: #69b1ff;
}

.statusDefault {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 50px;
  border: 1px solid #4870FE;
  background-color: #FFFFFF;
  color: #4870FE;
}

.statusWork {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 50px;
  border-color: #FF9500;
  background-color: #FF9500;
  color: #FFFFFF;
}

.statusTrue {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 50px;
  border-color: #34C759;
  background-color: #34C759;
  color: #FFFFFF;
}

.statusFalse {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 7px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 50px;
  border-color: #FF3B30;
  background-color: #FF3B30;
  color: #FFFFFF;
}

.actions {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #DAE2FF;
  background: #EDF1FF;
}

.actionsHead {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #3438E0;
}

.actionsText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
}

.button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px 7px 12px;
  gap: 10px;
  border-radius: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.deadlineHasTime {
  color: #34C759;
}

.deadlineWarning {
  color: #FAAD14;
}

.deadlineExpired {
  color: #FF4D4F;
}

.noTasks {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  background-color: #FFF;
}
