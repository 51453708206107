.actionDrawerWrap {
  width: 50%;
  padding: 24px;
  background-color: white;
}

.actionDrawerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.actionDrawerDescr {
  margin: 16px 0 16px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}