.bodyStyle {
  font-family: "TildaSans", sans-serif !important;
}

.generalInfo {
  background: #FFFFFF;
}

:global .ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  width: auto;
}

:global .ant-float-btn .ant-float-btn-body .ant-float-btn-content {
  padding: 0 1px;
}

:global .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s !important;
}
