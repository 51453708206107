.modalHeader {
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
		color: #101928;
}

.buttonBack {
		text-align: center;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		color: #101928;
		border: 1px solid #D1D6DD;
}

.buttonReject {
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
}

.buttonReject:hover {
		color: #ff7875 !important;
}

.buttonDisable {
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
}

.buttonOk {
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
}

.tariffText {
		margin-bottom: 20px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		color: #67768C;
}
