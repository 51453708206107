.container {
  max-width: 1920px;
  padding: 16px;
  height: 100%;
}

.returnBtn {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  font-weight: 500;
}

.docFile {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contractCardHeader {
  margin-bottom: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.contractParamsBlock {
  padding: 18px;
  background-color: #fff;
  border-radius: 8px;
}

.contractParamsHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.contractParamsLabel {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}


.contractParamsData {
  display: inline-block;
  width: 100%;
  line-height: 18px;
}
