.header {
  margin: 0 0 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  color: #8591A3 !important;
}

.resultCard {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #F7F8F9;
  border-radius: 8px
}

.resultCardHeaderWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #E8EAEE;
}

.resultHeader {
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.resultError {
  color: #FF3B30;
}
