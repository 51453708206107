.menuHeight {
  min-height: 80vh;
  background-color: #101928;
  color: #FFFFFF;
}

.menuDown {
  background-color: #101928;
  color: #FFFFFF;
}

.menuText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

@media (max-height: 780px) {
  .menuHeight {
    min-height: 75vh;
  }
}

@media (max-height: 650px) {
  .menuHeight {
    min-height: 70vh;
  }
}

:global(.ant-menu-dark.ant-menu-inline .ant-menu-item-selected) {
  background-color: #3438E0 !important;
}

:global(.ant-menu-dark.ant-menu-inline .ant-menu-item-selected:hover) {
  background-color: #4870FE !important;
}

:global(.ant-menu-dark.ant-menu-inline .ant-menu-item:not(.ant-menu-item-selected):hover) {
  background-color: rgba(103, 118, 140, 0.2) !important;
}

:global(.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item-selected) {
  background-color: #3438E0 !important;
}

:global(.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item-selected:hover) {
  background-color: #4870FE !important;
}

:global(.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item:not(.ant-menu-item-selected):hover) {
  background-color: rgba(103, 118, 140, 0.2) !important;
}

:global(.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-item) {
  display: flex;
  padding: 10px;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-left: 17%;
  transition: margin-left 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, padding 0.3s ease-out;
}

:global(.ant-menu-dark.ant-menu-inline .ant-menu-item) {
  padding: 10px !important;
  transition: margin-left 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, padding 0.3s ease-out;
}
