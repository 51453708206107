.questionsBlockStyle {
  max-width: 1200px;
  margin: 0 auto;
}

.questionsBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 37px;
  padding: 52px 45px 62px;
  background-color: #3438e0;
}

.questionsLabel {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
}

.questionsText {
  margin-bottom: 80px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
}

.inputGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.commonQuestionStyle {
  height: 56px;
  width: 30%;
  margin-right: 20px;
  background-color: #3438e0;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 2px;
}

.questionInput {
  outline: none;
}

.questionInput::placeholder {
  color: #999bef;
}

.questionInput::-webkit-outer-spin-button,
.questionInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.questionInput[type="number"] {
  -moz-appearance: textfield;
}

.questionButton {
  height: 56px;
  padding: 10px 40px 10px 40px;
  border-radius: 2px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #FFFFFF;
}

.questionsFooter {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  color: #ffffff;
}

.dataProcessing {
  display: inline-block;
  margin-left: 5px;
  color: rgb(255, 255, 255);
  text-decoration: underline;
  text-decoration-color: rgb(255, 255, 255);
}

.dataProcessing:hover {
  color: rgb(255, 255, 255);
  text-decoration: underline;
  text-decoration-color: rgb(255, 255, 255);
}

@media (max-width: 980px) {
  .inputGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .commonQuestionStyle  {
    width: 100%;
    margin-right: 18px;
    margin-bottom: 18px;
    border-radius: 0;
    outline: none;
  }
}