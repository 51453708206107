body {
  background-color: #F9F9F9;
}

.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  color: #8591A3 !important;
}

.tableCell {
  margin: -16px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.modalContainer {
  display: flex;
  flex-direction: column;
}

.modalHeader {
  margin-left: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.modalClose {
  position: absolute;
  top: 23px;
  right: 33px;
}

.modalText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.modalTextTask {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.modalInput {
  height: 5vh;
}

.modalDrop {
  width: 100%;
}

.clientsNumber {
  width: calc(50% - 6px);
}

.modalFooter {
  padding: 8px 8px;
}

.modalFooterButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalFooterButton > :first-child {
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonStyle {
  display: flex;
  width: 159px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
