.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.createVacancy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
