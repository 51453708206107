.container {
  border-radius: 8px;
}

.warningText {
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 8px;
  color: #111827;
  background-color: rgba(255, 82, 82, 0.1);
}

.transitionConfirm {
  width: 440px;
}

.cause {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 12px;
  border-radius: 6px;
  background: rgba(103, 118, 140, 0.05);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
}

.buttonBack {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonOk {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
