.priceBlock {
  max-width: 1200px;
  margin: 0 auto;
}

.priceStyle {
  display: flex;
  justify-content: space-between;
  padding: 160px 30px 0 40px;
}

.priceTextLabel {
  font-size: 40px;
  font-weight: 600;
  text-align: start;
}

.priceTextInfo {
  margin-top: 24px;
  font-weight: 300;
  font-size: 16px;
}

.writeToUsButton {
  margin-top: 100px;
  margin-bottom: 70px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 700;
  color: #f2f3f5;
  border: 2px solid #3438e0;
  border-radius: 0 !important;
  background-color: #3438e0 !important;
  text-align: center;
  cursor: pointer;
}

.imgPrice {
  display: inline-block;
  max-width: 500px;
  float: right;
  width: 100%;
}

.priceImg {
  width: 100%;
  height: 60%;
  object-fit: contain;
  object-position: 80px 0px;
}

@media (max-width: 980px) {
  .priceStyle {
    align-items: center;
    flex-direction: column;
    justify-content: start;
    margin: auto;
  }

  .priceImg {
    object-position: 50px 40px;
  }
}