.generalInfo {
  background: #FFFFFF;
}

.generalInfoStyle {
  max-width: 1200px;
  margin: 0 auto;
}

.generalStyle {
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: 10px;
  padding: 30px 0;
}

.generalTextLabel {
  margin-bottom: 24px;
  margin-right: auto;
  margin-left: auto;
  font-size: 38px;
  line-height: 1.23;
  font-weight: 500;
  color: #000000;
}

.underLine {
  display: block;
  width: 100%;
  max-width: 50px;
  height: 3px;
  margin-left: auto;
  margin-right: auto;
  background-color: #222222;
}

.generalTextInfo {
  max-width: 500px;
  height: 186px;
  margin-top: 23px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 300;
}

.imgInfo {
  max-width: 560px;
  display: inline;
  float: right;
  margin-right: 90px;
  width: 100%;
}

.homePicture {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.floatButton {
  width: 51px;
  height: 51px;
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.floatButton svg path {
  width: 40px;
}

.floatButton:hover {
  background: rgb(52 56 224) !important;
}

.floatButton:hover svg path {
  stroke: #FFFFFF;
}

@media (max-width: 980px) {
  .generalStyle {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
  }

  .generalTextLabel {
    margin-top: 24px;
  }
}