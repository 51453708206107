.actions {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #DAE2FF;
  background: #EDF1FF;
}

.actionsHead {
  margin-right: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
}

.button {
  display: flex;
  padding: 7px 12px;
  font-family: Helvetica Neue, Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.notificationsHeader {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.notificationsBody {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
