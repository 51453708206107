.container {
  max-width: 1920px;
  max-height: 1080px;
  margin: 16px;
}

.containerCollapsed {
  composes: container;
  width: 50%;
}

.cardsHeader > :first-child {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.clientCardHeader {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.buttonReturn {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0;
  margin-bottom: 8px;
  margin-left: -5px;
  font-weight: 500;
  color: #3438E0;
}

.buttonReturn svg g path {
  fill: #3438E0;
  transition: fill .2s ease-in-out;
}

.buttonReturn:hover svg g path {
  fill: #69b1ff;
}

.primaryButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 44px;
}

.organizationName {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #101928;
}

.clientCardTabs > div {
  margin-bottom: 18px !important;
}
