.modalTitle {
	display: block;
	margin-bottom: 20px;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.tooltip {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
}

.blockedInfo {
	margin-bottom: 20px;
	display: flex;
	padding: 12px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	border-radius: 12px;
	background: #F7F8F9;
}

.text {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
}

.statusText {
	composes: text;
	color: #67768C;
}

.activeButton {
	color: #101928;
	border: 1px solid #D1D6DD;
}

.textButton {
	height: 18px;
	padding: 0;
}

.blockResponsibleTag {
	margin-top: 4px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	border-radius: 4px;
	border: none;
	background: #F0F1F3;
}