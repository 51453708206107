.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.createResumeHeader {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.createResumeParamsBlock {
  padding: 20px;
  margin-bottom: 12px;
  background-color: #fff;
  border-radius: 8px;
}

.createResumeParamsHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.createResumeActionBlock {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
  margin-bottom: 12px;
  background-color: #fff;
  border-radius: 8px;
}

.uploadFileTypeDescr {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #67768C;
}

.fileListing {
  margin-top: 8px;
  max-height: 400px;
  gap: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fileListing::-webkit-scrollbar {
  width: 4px;
}

.fileListing::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}

.documentsUpload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 2px;
  color: #3438E0;
}

.documentsUpload:hover {
  background: #F0F1F3;
}

.documentsUpload .deleteIcon{
  opacity: 0;
  visibility: hidden;
}

.documentsUpload:hover .deleteIcon {
  opacity: 1;
  visibility: visible;
}

.deleteIcon {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 6px;
  border-radius: 2px;
  background-color: inherit;
  cursor: pointer;
}

.deleteIcon:hover {
  background: #E1E4E8;
}
