body {
  background-color: #F3F4F6;
}

.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

.containerHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.inputData {
  width: 100%;
  height: 44px;
  font-size: 16px;
  margin-right: 12px;
}

.tableCell {
  margin: -16px;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.tableHeader {
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.columnText {
  margin: -16px;
  padding: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.columnTextOrganization {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
  cursor: pointer;
}

.tooltip {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  color: #FFF;
}

.tooltip div span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.modalHeader {
  margin-left: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.modalClose {
  position: absolute;
  top: 23px;
  right: 33px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
}

.modalInput {
  height: 5vh;
}

.modalText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.modalTextTask {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.modalDrop {
  width: 100%;
}

.modalDropButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalFooter {
  padding: 8px 8px;
}

.modalFooterButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalFooterButton > :first-child {
  color: #101928;
  border: 1px solid #D1D6DD;
}

.buttonStyle {
  display: flex;
  width: 159px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.responsible div {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 1px 0 1px 0;
}

.blockPosition {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #67768C;
}

.blockResponsible {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.blockResponsibleTag {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  border: none;
  background: #F0F1F3;
}
