.container {
  max-width: 1920px;
  width: 100%;
  height: 100%;
  padding: 16px;
}

.createContractorHeader {
  margin-bottom: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.createContractorForm {
  margin-bottom: 12px;
  border-radius: 8px;
}

.createContractorFormBlock {
  padding: 20px;
  margin-bottom: 12px;
  background-color: #fff;
  border-radius: 8px;
}

.createContractorBlockHeader {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.createContractorActionBlock {
  border-radius: 8px;
}
