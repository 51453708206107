.container {
  max-width: 1920px;
  margin: 16px;
  height: 100%;
}

.tableCell {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #101928;
}

.settingsBtn {
  background-color: transparent;
  color: transparent;
}

.settingsBtn svg path {
  transition: fill .2s ease-in-out;
}

.settingsBtn:hover {
  background-color: transparent!important;
  color: transparent;
}

.settingsBtn:hover svg path {
  fill: #4870FE;
  transition: fill .2s ease-in-out;
}
