.headerContainer {
  display: flex;
  align-items: center;
  padding: 12px 156px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.buttonExit {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #101928;
  border: 1px solid #D1D6DD;
}

.container {
  max-width: 1920px;
  margin: 18px 156px 18px;
}

.questionnaireInfo {
  flex-basis: 21%;
  min-width: 288px;
}

.block {
  border-radius: 8px;
}

.stepsText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #3438E0;
}

.textGray {
  color: #B3BAC5;
}
