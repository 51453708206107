.container{
  padding: 10px;
  margin-bottom: -6px;
  margin-left: -6px;
  overflow-y: auto;
}

.container::-webkit-scrollbar {
  width: 4px;
}

.container::-webkit-scrollbar-thumb {
  background: rgba(103, 118, 140, 0.30);
  border-radius: 50px;
}

.docFile {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.historyHead {
  display: flex;
  gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #8591A3;
}

.historyText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #101928;
}

.message {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #67768C;
}

.messageText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  white-space: pre-wrap;
}

.hideMessage {
  color: #67768C;
}

.textButton {
  padding: unset;
}

.duplicatesHead {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}
