.container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 6px);
  border-radius: 8px;
}

.cardHeader {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.cause {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: rgba(103, 118, 140, 0.05);
}
