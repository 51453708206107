.accountsContainer {
  padding-top: 4px;
  background: #fff;
  border-radius: 8px;
}

.accountsSearchInput {
  width: 100%;
  height: 44px;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.accountsSearchIcon {
  width: 15px;
  height: 15px;
  color: #B3BAC5;
}

.noBanks {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  background-color: #FFF;
}
