.applicationCard {
  width: calc(50% - 6px);
}

.applicationHeader {
  margin-right: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.applicationInfoHeader {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.applicationInfoLabel {
  width: 100%;
  max-width: 251px;
  margin-bottom: 9px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.applicationInfoValue {
  width: 100%;
  min-width: 233px;
  margin-bottom: 9px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  white-space: pre-line;
  word-break: break-word;
}

.applicationParamsLabel {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #67768C;
}

.applicationParamsData {
  display: inline-block;
  width: 100%;
  line-height: 18px;
}

.returnBtn {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  font-weight: 500;
}
